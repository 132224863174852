import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://4c3b8d7c9c1948ab9a186948b260725e@o1036128.ingest.sentry.io/6003617',
  environment: process.env.NODE_ENV,
});
